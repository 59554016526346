import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { IOCreateOrSetPartnerAssociationCart } from '@simplisafe/ss-ecomm-data/cart'
import { IOPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/actions'
import { selectPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectActivePromoDiscountText, selectMonthsOfServiceDisplay, selectPromotionBanner
} from '@simplisafe/ss-ecomm-data/redux/select'
import { configureNewRelic, logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  Heading,
  PageBody, PageSection,
  PageWrapper, RichText, Text
} from '@simplisafe/ss-react-components/atoms'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { PartnerHero, ToastCheckMarkAlert } from '@simplisafe/ss-react-components/molecules'
import { graphql, PageProps } from 'gatsby'
import Img from 'gatsby-image'
import { set } from 'local-storage'
import {
  Just, Maybe, None,
} from 'monet'
import map from 'ramda/src/map'
import pathOr from 'ramda/src/pathOr'
import React, {
  FC, useContext, useEffect, useState
} from 'react'
import {
  Toast, toast, ToastBar, Toaster
} from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { PageContext } from '../../config/wrap-with-context'
import { PartnerPageQuery } from '../../graphql'
import { ContentfulComponent, getMappedComponent } from '../componentMappings'
import ContentfulRichText from '../components/ContentfulRichText'
import CountryRedirectModal from '../components/CountryRedirectModal'
import FooterComponent from '../components/FooterComponent'
import Header from '../components/Header'
import ImageWithArtDirection from '../components/ImageWithArtDirection'
import PartnerBanner from '../components/PartnerBanner'
import PopupWizard from '../components/PopupWizard'
import { HidePopupWizard } from '../contexts/hidePopupWizardContext'
import { SiteWideMessagesContext } from '../contexts/siteWideMessagesContext'
import ErrorBoundary from '../errorComponents/ErrorBoundary'
import useApplyPromoCode from '../hooks/useApplyPromoCode'
import { PageTitleContext } from '../tracking/pageTitleContext'
import { sendGtmCustomEvent, trackEventIsMobile } from '../util/analytics'
import getJson from '../util/getJson'
import { getLocalStorageCartIdAsString } from '../util/helper'
import { setPartnerCookie } from '../util/partnerCookie'
import { devParams } from '../util/queryParams'
import SEO from '../util/seo'

export type PartnerPageContext = {
  readonly id: string
} & PageContext

export type PartnerPageProps = PageProps<PartnerPageQuery, PartnerPageContext>

const PartnerPage: FC<PartnerPageProps> = ({
  data, pageContext, path: pagePath, location
}: PartnerPageProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    // This sets up New Relic with our configuration from ecomm-data
    configureNewRelic()
    // Scroll to top on route change
    window && window.scrollTo(0, 0)
  }, [])

  const pageTitle: string = path([ 'contentfulPartnerPage', 'pageTitle' ], data) || ''
  const partnerName: string = path( [ 'contentfulPartnerPage', 'partnerName' ], data) || ''
  const partnerGroup = pathOr<string, string>('', [ 'contentfulPartnerPage', 'partnerGroup' ], data)
  // format partner group to match keys in Commercetools
  const partnerGroupFormatted = partnerGroup.toLowerCase().replace(' ', '-')
  const customShopUrl = path([ 'contentfulPartnerPage', 'customShopUrl' ], data)

  const handlePartnerAssociationCartFailure = () => {
    logError(Error('there was an error when adding a partner association to cart'))
  }

  useEffect(() => {
    setPartnerCookie({
      customShopUrl: customShopUrl,
      partnerGroup: partnerGroupFormatted,
      partnerName: partnerName,
      partnerUrl: pagePath
    })

    partnerName && partnerGroupFormatted !== '' && dispatch(IOPartnerBanner(partnerName, partnerGroupFormatted))
    const cartId = getLocalStorageCartIdAsString()
    dispatch(IOCreateOrSetPartnerAssociationCart(partnerGroupFormatted, partnerName, cartId, handlePartnerAssociationCartFailure))
  }, [ customShopUrl, dispatch, partnerName, partnerGroupFormatted, pagePath ])
  const setPageTitle = useContext(PageTitleContext)
  const { trackEvent } = useTracking()
  useEffect(() => {
    setPageTitle(pageTitle)
  }, [ pageTitle, setPageTitle ])

  const partnerBanner = useSelector(selectPartnerBanner)
  const monthsOfServiceDisplay = useSelector(selectMonthsOfServiceDisplay(true, true))
  const discountText = useSelector(selectActivePromoDiscountText).map(text => `${text} off`)
  const lineItemText = useSelector(selectPromotionBanner).chain(chainProp('freeGiftItemPrimaryText'))

  const isTabletUp = useMediaQuery('TabletAndUp')
  useEffect(() => {
    trackEventIsMobile(trackEvent, isTabletUp)
  }, [ isTabletUp, trackEvent ])

  const [ hidePopups ] = useQueryParam(devParams.hidePopUps, BooleanParam)

  const [ hidePopupWizard, setHidePopupWizard ] = useState(true)

  const handleHidePopup = (hide: boolean) => setHidePopupWizard(hide)

  const termsAndConditions = <ContentfulRichText rawRichText={getJson(path([ 'contentfulPartnerPage', 'termsAndConditions' ], data))} />
  // @ts-ignore TODO the path in here returns a string, this needs a type guard
  const termsAndConditionsPosition: 'Co-Brand Box' | 'Hero Image' | 'Bottom' | 'None'  = path([ 'contentfulPartnerPage', 'termsAndConditionsPosition' ], data) || 'None'
  const termsAndConditionsTitle = path([ 'contentfulPartnerPage', 'termsAndConditionsTitle' ], data)
  const utmCode = path([ 'contentfulPartnerPage', 'template', 'utmCode' ], data)

  utmCode && set('utm_code', utmCode)

  useApplyPromoCode(Maybe.fromNull(utmCode))
  return (
    <ErrorBoundary>
      <HidePopupWizard.Provider value={{
        handleHidePopup,
        hidePopupWizard
      }}>
        <SiteWideMessagesContext.Provider value={safeProp('contentfulSiteWideMessages', data).orJust({})}>
          <PageWrapper>
            <SEO
              lang={path([ 'contentfulPartnerPage', 'node_locale' ], data) || 'en-US'}
              metaTitle={pageTitle}
              pageUrl={pagePath}
              productId={None()}
              seoDetails={{
                isNofollow: true,
                isNoindex: true
              }}
            />
            <Toaster toastOptions={{ style: { padding: 0 } }}>
              {(t: Toast) => (
                <ToastBar toast={t}>
                  {({ message }) => (
                    <ToastCheckMarkAlert
                      hasCloseButton={true}
                      // @ts-ignore
                      message={message}
                      onCloseHandler={() => {
                        sendGtmCustomEvent({
                          event: 'toastAlert',
                          eventAction: 'closeToastAlert',
                          eventCategory: 'UTMAdditionAlert',
                          eventLabel: 'closedToastAlert'
                        })
                        toast.dismiss(t.id)
                        window.localStorage.utm_code && window.localStorage.removeItem('utm_code')
                      }}
                    />
                  )}
                </ToastBar>
              )}
            </Toaster>
            {partnerBanner.map(data =>
              <PartnerBanner
              	backgroundColor={data.backgroundColor.orUndefined()}
                discountSecondaryText={data.discountSecondaryText.orUndefined()}
                discountText={discountText.orSome('15% off')}
                displayEmailInputField={data.displayEmailInputField.orUndefined()}
                key="partner-banner"
                lineItemText={lineItemText.orSome('free SimpliCam')}
                linkPath={data.linkPath.orUndefined()}
                logoDescription={data.logoDescription.orUndefined()}
                logoUrl={data.logoUrl.orUndefined()}
                monitoringText={`${monthsOfServiceDisplay} of monitoring`}
                partnerName={partnerName}
                primaryOfferText={data.primaryOfferText.orUndefined()}
                primaryTextColor={data.primaryTextColor.orUndefined()}
                secondaryOfferText={data.secondaryOfferText.orUndefined()}
                secondaryOfferTextMobile={data.secondaryOfferTextMobile.orUndefined()}
                secondaryTextColor={data.secondaryTextColor.orUndefined()}
              ></PartnerBanner>
            )}
            {safePath([ 'contentfulPartnerPage', 'template', 'header' ], data).map(header => (
              // eslint-disable-next-line react/jsx-key
              <ErrorBoundary>
                <Header
                  data={header}
                />
              </ErrorBoundary>
            ))
              .orUndefined()}
            <ErrorBoundary>
              <PageBody>
                {Just(heroImage => heroBodyJson => (
                  <ErrorBoundary>
                    <PartnerHero
                      appElementId='___gatsby'
                      body={
                        <RichText json={heroBodyJson} />
                      }
                      coBrandLogo={
                        safePath([ 'contentfulPartnerPage', 'coBrandLogo' ], data).map(logo => (
                          // eslint-disable-next-line react/jsx-key
                          <Img
                            alt={prop('description', logo) || ''}
                            fixed={prop('fixed', logo)}
                            imgStyle={{ position: 'static' }}
                            style={{
                              height: 'auto',
                              width: '100%'
                            }}
                          />
                        ))
                          .orUndefined()
                      }
                      coBrandTitle={safePath([ 'contentfulPartnerPage', 'coBrandTitle' ], data).orUndefined()}
                      img={
                        <ImageWithArtDirection data={heroImage}
                          style={{ height: '100%', }} />
                      }
                      termsAndConditions={termsAndConditions}
                      termsAndConditionsPosition={termsAndConditionsPosition !== 'Bottom' ? termsAndConditionsPosition : 'None'}
                      termsAndConditionsTitle={termsAndConditionsTitle}
                    />
                  </ErrorBoundary>
                ))
                  .apTo(safePath([ 'contentfulPartnerPage', 'heroImage' ], data))
                  .apTo(safePath([ 'contentfulPartnerPage', 'heroBody', 'json' ], data))
                  .orUndefined()
                }
                {
                  safePath([ 'contentfulPartnerPage', 'template', 'mainContent' ], data)
                    .map(
                      map((entry: ContentfulComponent) => {
                        const Component = getMappedComponent(entry)
                        return Component ?
                          <ErrorBoundary key={entry.id}>
                            <PageSection>
                              <Component
                                data={entry}
                                location={location}
                                pageContext={pageContext}
                              />
                            </PageSection>
                          </ErrorBoundary> :
                          null
                      })
                    )
                    .orUndefined()
                }
                {termsAndConditionsPosition === 'Bottom' &&
                  <ErrorBoundary>
                    <PageSection>
                      {termsAndConditionsTitle && <Heading element="h2" headingAspect="h5">{termsAndConditionsTitle}</Heading>}
                      <Text textSize="sm">
                        {termsAndConditions}
                      </Text>
                    </PageSection>
                  </ErrorBoundary>}
              </PageBody>
            </ErrorBoundary>
            {safePath([ 'contentfulPartnerPage', 'template', 'footer' ], data).map(footer => (
              // eslint-disable-next-line react/jsx-key
              <ErrorBoundary>
                <FooterComponent
                  data={footer}
                />
              </ErrorBoundary>
            ))
              .orUndefined()}
            {
              safePath([ 'contentfulPartnerPage', 'template', 'countryRedirectModal' ], data).map(countryRedirectModal => (
                !hidePopups &&
                <ErrorBoundary>
                  <CountryRedirectModal
                    data={countryRedirectModal} />
                </ErrorBoundary>
              ))
                .orUndefined()
            }
            {
              safePath([ 'contentfulPartnerPage', 'template', 'popupWizard' ], data).map(popupWizard =>
                // eslint-disable-next-line react/jsx-key
                <ErrorBoundary>
                  <PopupWizard data={popupWizard} />
                </ErrorBoundary>
              )
                .orUndefined()
            }
          </PageWrapper>
        </SiteWideMessagesContext.Provider>
      </HidePopupWizard.Provider>
    </ErrorBoundary>
  )
}

// the #graphql tag is required for eslint and GraphQL for VSCode
export const query = graphql`#graphql
  query PartnerPage($id: String, $locale: String){
    contentfulSiteWideMessages(node_locale: {eq: $locale}) {
      ...siteWideMessages
    }
    contentfulPartnerPage(id: {eq: $id}) {
      pageTitle
      node_locale
      partnerName
      template {
        utmCode
        header {
          ... on ContentfulHeader {
            ...contentfulHeaderFragment
          }
        }
        mainContent {
          ... on ContentfulPrefectSystemBanner {
            ...perfectSystemBanner
          }
          ... on ContentfulAdditionalInfoBanner {
            ...OverlayBanner
          }
          ... on ContentfulCarousel {
            ...Carousel
          }
          ... on ContentfulTwoColumn {
            ...contentfulTwoColumnFragment
          }
          ... on ContentfulBanner {
            ...contentfulBanner
          }
          ... on ContentfulCompanyReviewBanner {
            ...CompanyReviewBannerInformation
          }
          ... on ContentfulContentSelector {
            ...contentSelector
          }
          ... on ContentfulDeviceVariations {
            ...deviceVariationsFragment
          }
          ... on ContentfulSmallTextSection {
            ...smallTextSectionFragment
          }
          ... on ContentfulResponsiveContainer {
            ...responsiveContainer
          }
          ... on ContentfulGroupSection {
            ...contentfulGroupSectionFragment
          }
        }
        footer {
          ... on ContentfulFooter {
            ...contentfulFooter
          }
        }
        popupWizard {
          ... on ContentfulFloatingBar {
            ...popupWizardFragment
          }
        }
        countryRedirectModal{
          ... on ContentfulSmallTextSection {
            ...smallTextSectionFragment
          }
        }
      }
      partnerGroup
      partnerName
      customShopUrl
      coBrandTitle
      termsAndConditionsPosition
      termsAndConditionsTitle
      coBrandLogo {
        description
        fixed(width: 160) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      heroImage {
        ... on ContentfulImageWithArtDirection {
          ...imageWithArtDirection
        }
      }
      heroBody {
        json
      }
      termsAndConditions {
        json
      }
    }
  }
`

export default PartnerPage
